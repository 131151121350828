// import { Injectable } from '@angular/core';
// import {
// 	HttpRequest,
// 	HttpHandler,
// 	HttpEvent,
// 	HttpInterceptor
// } from '@angular/common/http';
// import { Observable, of } from 'rxjs';
// import { NgxUiLoaderService } from 'ngx-ui-loader';
// import { catchError, finalize } from 'rxjs/operators';

// @Injectable()
// export class ApiInterceptor implements HttpInterceptor {

// 	private _activeRequest = 0;

// 	constructor(private _ngxUiLoaderService: NgxUiLoaderService) { }

// 	// intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
// 	// 	if (this._activeRequest === 0) {
// 	// 		this._ngxUiLoaderService.startBackground('do-background-things');
// 	// 		//this._ngxUiLoaderService.start();
// 	// 	}
// 	// 	this._activeRequest++;
// 	// 	return next.handle(request).pipe(finalize(() => this._stopLoader()));
// 	// }

// 	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
// 		if (this._activeRequest === 0) {
// 		  this._ngxUiLoaderService.startBackground('do-background-things');
// 		}
// 		this._activeRequest++;
	  
// 		return next.handle(request).pipe(
// 		  catchError((error) => {
// 			// Aquí puedes manejar el error de la solicitud si lo deseas
// 			return of(error); // Devolver un error vacío o el error mismo
// 		  }),
// 		  finalize(() => this._stopLoader())
// 		);
// 	  }

// 	private _stopLoader() {
// 		this._activeRequest--;
// 		if (this._activeRequest === 0) {
// 			this._ngxUiLoaderService.stopBackground('do-background-things');
// 			//this._ngxUiLoaderService.stop();
// 		}
// 	}
// }


import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private _activeRequest = 0;
  private cancelSubject: Subject<any> = new Subject();

  constructor(private _ngxUiLoaderService: NgxUiLoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this._activeRequest === 0) {
      this._ngxUiLoaderService.startBackground('do-background-things');
    }

    this._activeRequest++;

    // Pasar el Subject de cancelación a la solicitud
    return next.handle(request).pipe(
      finalize(() => this._stopLoader())
    );
  }

  // Método para cancelar la solicitud
  cancelRequest(): void {
    this.cancelSubject.next();
  }

  private _stopLoader() {
    this._activeRequest--;
    if (this._activeRequest === 0) {
      this._ngxUiLoaderService.stopBackground('do-background-things');
    }
  }
}

